.p4p-popup {
    padding: 1rem;
    font-family: 'Montserrat';
}

.p4p-popup .images {
    display: flex;
    align-items: center;
}

.p4p-popup .images > img:last-of-type {
    padding: 0.25rem;
}

.p4p-popup .images > img:first-of-type {
    border-right: 1px solid #e7e7e7;
}

.p4p-popup .prize-title {
    font-weight: 900;
    font-style: italic;
}

.p4p-popup p {
    font-weight: 300;
}

.p4p-popup img {
    width: 128px;
    height: auto;
    margin: auto;
}

.p4p-popup .sponsor-name {
    padding: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    color: white;
    background-color: #00C06A;
    border-radius: 100%;
    font-size: 1.15rem;
}

.p4p-popup .title {
    margin-bottom: 0;
}

.p4p-popup .subtitle {
    margin-top: 0;
}

.p4p-popup .prize .prize-progress-outer {
    border: 1px solid #eeeeee;
    border-radius: 4px;
}

.p4p-popup .prize .prize-progress-label {
    text-align: left;
    margin: 0;
}

.p4p-popup .prize .prize-draw-label {
    text-align: left;
    font-size: 16px;
}

.p4p-popup .prize .prize-draw-label > span {
    font-size: 20px;
    font-weight: 900;
    color: #00C06A;
}

.p4p-popup hr {
    border: 2px solid #00C06A;
}

.p4p-popup .points {
    font-size: 2rem;
    font-weight: 900;
    width: 64px;
    height: 64px;
    color: #00C06A;
    margin-top: 1rem;
    margin-bottom: 0;
    border: 4px solid #00C06A;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.p4p-popup .draws {
    font-size: 2rem;
    font-weight: 900;
    /*width: 64px;*/
    /*height: 64px;*/
    color: #00C06A;
    margin-top: 1rem;
    margin-bottom: 0;
    /*border: 4px solid #00C06A;*/
    /*border-radius: 100%;*/
    /*display: inline-flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

@media screen and (max-width: 600px) {
    .p4p-popup {
        padding: 0.25rem;
    }

    .p4p-popup img {
        width: 96px;
    }

    .p4p-popup .points {
        margin-top: 0.25rem;
    }
}
